const BASE_URL = process.env.VUE_APP_BASE_URL;
import axios from "axios";
const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};
export const sliderApi = {

  async slider() {
    const response = await axios(`${BASE_URL}/slider`, {
      method: "GET",
      headers: {
        ...headers,

      },
    })
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async addSlider(slider) {
    const response = await fetch(`${BASE_URL}/slider`, {
      method: "POST",
      headers: {
        ...headers,

      },
      body: JSON.stringify(slider)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },
  async deleteSlider(id) {
    const response = await axios(`${BASE_URL}/slider/${id}`, {
      method: "DELETE",
      headers: {
        ...headers
      },
      data: JSON.stringify({ id })
    }).catch(err => console.error("err", err.message));
    const json = await response
    return {
      ...json,
      status: response.status
    };
  },
  async getSliderById(id) {
    const response = await axios(`${BASE_URL}/slider/${id}`, {
      method: "GET",
      headers: {
        ...headers,
      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async updateSlider(id, slider) {
    const response = await fetch(`${BASE_URL}/slider/${id}`, {
      method: "PUT",
      headers: {
        ...headers,

      },
      body: JSON.stringify(slider)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },

  async lifestyle() {
    const response = await axios(`${BASE_URL}/lifestyle`, {
      method: "GET",
      headers: {
        ...headers,

      },
    })
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async addLifestyle(lifestyle) {
    const response = await fetch(`${BASE_URL}/lifestyle`, {
      method: "POST",
      headers: {
        ...headers,

      },
      body: JSON.stringify(lifestyle)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },
  async deleteLifestyle(id) {
    const response = await axios(`${BASE_URL}/lifestyle/${id}`, {
      method: "DELETE",
      headers: {
        ...headers
      },
      data: JSON.stringify({ id })
    }).catch(err => console.error("err", err.message));
    const json = await response
    return {
      ...json,
      status: response.status
    };
  },
  async getLifestyleById(id) {
    const response = await axios(`${BASE_URL}/lifestyle/${id}`, {
      method: "GET",
      headers: {
        ...headers,
      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async updateLifestyle(id, lifestyle) {
    const response = await fetch(`${BASE_URL}/lifestyle/${id}`, {
      method: "PUT",
      headers: {
        ...headers,

      },
      body: JSON.stringify(lifestyle)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },

  async amenitiesSlider() {
    const response = await axios(`${BASE_URL}/amenitiesSlider`, {
      method: "GET",
      headers: {
        ...headers,

      },
    })
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async designSlider() {
    const response = await axios(`${BASE_URL}/designSlider`, {
      method: "GET",
      headers: {
        ...headers,

      },
    })
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async avantGardeSlider() {
    const response = await axios(`${BASE_URL}/avantGardeSlider`, {
      method: "GET",
      headers: {
        ...headers,

      },
    })
    const data = await response
    return {
      data,
      status: response.status
    };
  },

  async getDesignById(id) {
    const response = await axios(`${BASE_URL}/design/${id}`, {
      method: "GET",
      headers: {
        ...headers,
      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async updateDesign(id, design) {
    const response = await fetch(`${BASE_URL}/design/${id}`, {
      method: "PUT",
      headers: {
        ...headers,

      },
      body: JSON.stringify(design)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },

  async getAvantGardeById(id) {
    const response = await axios(`${BASE_URL}/avantgarde/${id}`, {
      method: "GET",
      headers: {
        ...headers,
      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async updateAvantGarde(id, avantgarde) {
    const response = await fetch(`${BASE_URL}/avantgarde/${id}`, {
      method: "PUT",
      headers: {
        ...headers,

      },
      body: JSON.stringify(avantgarde)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },

  async getAmenitiesById(id) {
    const response = await axios(`${BASE_URL}/amenities/${id}`, {
      method: "GET",
      headers: {
        ...headers,
      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async updateAmenities(id, amenities) {
    const response = await fetch(`${BASE_URL}/amenities/${id}`, {
      method: "PUT",
      headers: {
        ...headers,

      },
      body: JSON.stringify(amenities)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },

  async getLocationById(id) {
    const response = await axios(`${BASE_URL}/location/${id}`, {
      method: "GET",
      headers: {
        ...headers,
      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async updateLocation(id, location) {
    const response = await fetch(`${BASE_URL}/location/${id}`, {
      method: "PUT",
      headers: {
        ...headers,

      },
      body: JSON.stringify(location)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },

  async getHomePageById(id) {
    const response = await axios(`${BASE_URL}/homepage/${id}`, {
      method: "GET",
      headers: {
        ...headers,
      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async updateHomePage(id, homepage) {
    const response = await fetch(`${BASE_URL}/homepage/${id}`, {
      method: "PUT",
      headers: {
        ...headers,

      },
      body: JSON.stringify(homepage)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },

  async getAboutById(id) {
    const response = await axios(`${BASE_URL}/about/${id}`, {
      method: "GET",
      headers: {
        ...headers,
      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async updateAbout(id, about) {
    const response = await fetch(`${BASE_URL}/about/${id}`, {
      method: "PUT",
      headers: {
        ...headers,

      },
      body: JSON.stringify(about)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },

  async getCounterById(id) {
    const response = await axios(`${BASE_URL}/counter/${id}`, {
      method: "GET",
      headers: {
        ...headers,
      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async updateCounter(id, counter) {
    const response = await fetch(`${BASE_URL}/counter/${id}`, {
      method: "PUT",
      headers: {
        ...headers,

      },
      body: JSON.stringify(counter)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },

  async getContactDetailsById(id) {
    const response = await axios(`${BASE_URL}/contactDetails/${id}`, {
      method: "GET",
      headers: {
        ...headers,
      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async updateContactDetails(id, contactDetails) {
    const response = await fetch(`${BASE_URL}/contactDetails/${id}`, {
      method: "PUT",
      headers: {
        ...headers,

      },
      body: JSON.stringify(contactDetails)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },

  async getContactDataById(id) {
    const response = await axios(`${BASE_URL}/contactData/${id}`, {
      method: "GET",
      headers: {
        ...headers,
      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async updateContactData(id, contactData) {
    const response = await fetch(`${BASE_URL}/contactData/${id}`, {
      method: "PUT",
      headers: {
        ...headers,

      },
      body: JSON.stringify(contactData)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },

};