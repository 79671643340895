const BASE_URL = process.env.VUE_APP_BASE_URL;
import axios from "axios";
const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

export const contactApi = {


  async contact() {
    const response = await axios(`${BASE_URL}/contact`, {
      method: "GET",
      headers: {
        ...headers,

      },
    })
    const data = await response
    return {
      data,
      status: response.status
    };
  },

};